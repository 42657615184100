<template>
    <!-- 서브페이지 내용 -->
    <div class="con-wrap">
        <CarrotTitle title="고객사일지 상세"></CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>
                    <table class="table-row">
                        <colgroup>
                            <col width="180">
                            <col width="*">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>작성자</th>
                                <td>{{ view.info.hq_ename }}({{ view.info.hq_kname }})</td>
                            </tr>
                            <tr>
                                <th>일시</th>
                                <td>{{ view.info.vdate }}</td>
                            </tr>
                            <tr>
                                <th>외근목적</th>
                                <td>{{ view.info.rtype }}</td>
                            </tr>
                            <tr>
                                <th>고객사</th>
                                <td>{{ view.info.company_kname }}</td>
                            </tr>
                            <tr>
                                <th>업체담당자</th>
                                <td>{{ view.info.company_manager }}</td>
                            </tr>
                            <tr>
                                <th>상담내용</th>
                                <td class="preline"><span v-html="view.info.contents"></span></td>
                            </tr>
                            <tr>
                                <th>수업 지속성</th>
                                <td>
                                    <img v-if="view.info.class_i > 0" :src="require('@/assets/img/db_relation_0' + view.info.class_i + '.png')" alt="ico">
                                    <span v-if="view.info.class_i == 0">-</span>
                                    <!-- db_relation_01~04 -->
                                </td>
                            </tr>
                            <tr>
                                <th>관계 지속성</th>
                                <td>
                                    <img v-if="view.info.relation_i > 0" :src="require('@/assets/img/db_durability_0' + view.info.relation_i + '.png')" alt="ico">
                                    <span v-if="view.info.relation_i == 0">-</span>
                                    <!-- db_durability_01~05 -->
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <h3 class="block-title mt-40 mb-20">외근수당</h3>
                    <table class="table-row">
                        <colgroup>
                            <col width="180">
                            <col width="*">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>목적</th>
                                <td>{{ view.info.rtype }}</td>
                            </tr>
                            <tr>
                                <th>클래스</th>
                                <td>{{ view.info.classname }}</td>
                            </tr>
                            <tr>
                                <th>수당</th>
                                <td>{{ view.info.price }} 원</td>
                            </tr>
                        </tbody>
                    </table>

                    <button @click="view.doCancel" class="btn-default float-left mt-30 mr-10">목록</button>
                    <button v-if="view.info.is_owner=='Y'" @click="view.doDelete" class="btn-default float-left mt-30">삭제</button>
                    <router-link v-if="view.info.is_owner=='Y'" :to="{ name:'customerManagement-outsideWorkMod-idx', params:{ idx:view.idx } }"><button @click="view.doSubmit" class="btn-default float-right mt-30">수정</button></router-link>
                    <div class="clear"></div>
                </div>
            </div>
        </div>
    </div>
    <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src
import { onMounted, reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import Swal from 'sweetalert2'


export default {
    layout:"customerManagement",
    components: {
    },
    setup() {
        const router = new useRouter();
        const route  = new useRoute();
        const toast = useToast()

        const view = reactive({
            idx : 0,
            info : {
                class_i    : 1,
                relation_i : 1,
            },

            doCancel : () => {
                router.go(-1);
            },

            doDelete : () => {
                Swal.fire({
                    title : "고객사일지 삭제",
                    text: '삭제하시겠습니까?',
                    showCancelButton: true,
                }).then((res) => {
                    if (res.isConfirmed) {
                        let params = {
                            idx : view.idx
                        };
                        axios.post("/rest/customermgr/delOutsideWork", params).then((res) => {
                            if( res.data.err == 0 ){
                                router.push({
                                    name: 'customerManagement-scheduleVisitWeekly'
                                })
                            } else {
                                if(res.data.err_msg) toast.error(res.data.err_msg);
                            }
                        });
                    }
                });
            },

            doSearch : () => {
                let params = {
                    idx : view.idx
                };

                axios.get("/rest/customermgr/getOutsideWorkInfo", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        view.info = res.data;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            }
        });

        onMounted(() => {
            // Mounted
            view.idx = route.params.idx?route.params.idx:0;

            if ( !view.idx ) {
                router.back(-1);
            }

            view.doSearch();
        });

        return {view};
    }
}
</script>

<style lang="scss" scoped>
</style>